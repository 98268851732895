<template>
  <main class="mr-4 md:mr-0 mb-8">
    <trac-loading v-if="isLoading" />

    <trac-validation-observer slim v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(createStore)" class="md:ml-8">
        <trac-back-button>Stores</trac-back-button>
        <div class="flex justify-between items-center">
          <ul class="flex-col mt-12 mb-8">
            <li>Stores</li>
            <li class="font-bold mt-4 text-xl">Add store</li>
          </ul>
          <trac-button buttonType="submit">{{
            store._id ? "Update Store" : "Add Store"
          }}</trac-button>
        </div>

        <div class="md:w-6/12 p-8 big-shadow">
          <h3 class="mb-4">Store Info</h3>
          <div class="mt-5 w-full">
            <trac-validation-provider
              name="store name"
              rules="required"
              v-slot="{ errors }"
            >
              <!-- :disabled="store.name.toLowerCase() === 'main'" -->
              <trac-input
                v-model="store.name"
                placeholder="Store Name"
              ></trac-input>
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider>
          </div>
          <div class="mt-5 w-full">
            <trac-validation-provider
              name="email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <trac-input
                v-model="store.email"
                placeholder="Email Address"
              ></trac-input>
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider>
          </div>
          <div class="mt-5 w-full">
            <trac-validation-provider
              name="phone number"
              rules="required|positive"
              v-slot="{ errors }"
            >
              <trac-input
                v-model="store.phone"
                placeholder="Phone Number"
              ></trac-input>
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider>
          </div>
        </div>

        <div class="md:w-6/12 p-8 big-shadow mt-8">
          <h3 class="mb-4">Store Address</h3>
          <div class="mt-5 w-full">
            <trac-input
              v-model="store.address1"
              placeholder="Address Line 1"
            ></trac-input>
          </div>
          <div class="mt-5 w-full">
            <trac-input
              v-model="store.address2"
              placeholder="Address Line 2"
            ></trac-input>
          </div>
          <div class="mt-5 w-full">
            <trac-input v-model="store.town" placeholder="Town"></trac-input>
          </div>
          <div class="mt-5 w-full">
            <trac-input v-model="store.city" placeholder="City"></trac-input>
          </div>
          <div class="mt-5 w-full">
            <trac-validation-provider name="state" rules="" v-slot="{ errors }">
              <trac-input
                v-model="store.state"
                placeholder="State"
              ></trac-input>
              <trac-input-error v-if="errors[0]">{{
                errors[0]
              }}</trac-input-error>
            </trac-validation-provider>
          </div>
        </div>
      </form>
    </trac-validation-observer>
  </main>
</template>

<script>
import {
  GET_LOCAL_DB_DATA,
  GET_USER_BUSINESS_DATA,
  GET_USER_BUSINESS_ID,
  GET_USER_DATA,
  GET_USER_TOKEN,
  SAVE_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";
import { eventBus } from "./../../main";

export default {
  data() {
    return {
      isLoading: false,
      store: {
        address1: "",
        address2: "",
        business_id: GET_USER_BUSINESS_ID(),
        business_name: GET_USER_BUSINESS_DATA().name,
        city: "",
        email: "",
        name: "",
        phone: "",
        register: false,
        state: "",
        town: "",
      },
    };
  },
  created() {
    if (GET_LOCAL_DB_DATA("store-edit-details")) {
      this.store = GET_LOCAL_DB_DATA("store-edit-details");
    }
  },
  methods: {
    async createStore() {
      this.isLoading = true;
      if (GET_LOCAL_DB_DATA("store-edit-details")) {
        await this.$store.dispatch("UPDATE_STORE", this.store);
      } else {
        await this.$store.dispatch("CREATE_STORE", this.store);
      }
      const res = this.$store.getters["GET_STORE_RES"];

      this.$store.commit("STORE_ACTION_RES", null);

      if (res.status) {
        // Update local store
        const user = GET_USER_DATA();
        const token = GET_USER_TOKEN();

        user.stores.push({ id: res.data._id, name: res.data.name });

        SAVE_LOCAL_DB_DATA("traction-app-user-data", { token, user });
        if (GET_LOCAL_DB_DATA("store-edit-details")) {
        } else {
          // Add newly created store to products store stocks
          await this.$store.dispatch("FETCH_ALL_PRODUCTS");
          const productsRes = this.$store.getters["GET_ALL_PRODUCT"];

          if (productsRes.status) {
            productsRes.items.forEach((product) => {
              if (product.variation) {
                product.varieties.forEach((variant) => {
                  variant.store_stock.push({
                    re_order: 0,
                    stock: 0,
                    store_id: res.data._id,
                    store_name: res.data.name,
                  });
                });
              } else {
                (product.store_stock || []).push({
                  re_order: 0,
                  stock: 0,
                  store_id: res.data._id,
                  store_name: res.data.name,
                });
              }
            });
          }
        }

        this.$router.back();
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
