var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"mr-4 md:mr-0 mb-8"},[(_vm.isLoading)?_c('trac-loading'):_vm._e(),_c('trac-validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"md:ml-8",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createStore)}}},[_c('trac-back-button',[_vm._v("Stores")]),_c('div',{staticClass:"flex justify-between items-center"},[_c('ul',{staticClass:"flex-col mt-12 mb-8"},[_c('li',[_vm._v("Stores")]),_c('li',{staticClass:"font-bold mt-4 text-xl"},[_vm._v("Add store")])]),_c('trac-button',{attrs:{"buttonType":"submit"}},[_vm._v(_vm._s(_vm.store._id ? "Update Store" : "Add Store"))])],1),_c('div',{staticClass:"md:w-6/12 p-8 big-shadow"},[_c('h3',{staticClass:"mb-4"},[_vm._v("Store Info")]),_c('div',{staticClass:"mt-5 w-full"},[_c('trac-validation-provider',{attrs:{"name":"store name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('trac-input',{attrs:{"placeholder":"Store Name"},model:{value:(_vm.store.name),callback:function ($$v) {_vm.$set(_vm.store, "name", $$v)},expression:"store.name"}}),(errors[0])?_c('trac-input-error',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"mt-5 w-full"},[_c('trac-validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('trac-input',{attrs:{"placeholder":"Email Address"},model:{value:(_vm.store.email),callback:function ($$v) {_vm.$set(_vm.store, "email", $$v)},expression:"store.email"}}),(errors[0])?_c('trac-input-error',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"mt-5 w-full"},[_c('trac-validation-provider',{attrs:{"name":"phone number","rules":"required|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('trac-input',{attrs:{"placeholder":"Phone Number"},model:{value:(_vm.store.phone),callback:function ($$v) {_vm.$set(_vm.store, "phone", $$v)},expression:"store.phone"}}),(errors[0])?_c('trac-input-error',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"md:w-6/12 p-8 big-shadow mt-8"},[_c('h3',{staticClass:"mb-4"},[_vm._v("Store Address")]),_c('div',{staticClass:"mt-5 w-full"},[_c('trac-input',{attrs:{"placeholder":"Address Line 1"},model:{value:(_vm.store.address1),callback:function ($$v) {_vm.$set(_vm.store, "address1", $$v)},expression:"store.address1"}})],1),_c('div',{staticClass:"mt-5 w-full"},[_c('trac-input',{attrs:{"placeholder":"Address Line 2"},model:{value:(_vm.store.address2),callback:function ($$v) {_vm.$set(_vm.store, "address2", $$v)},expression:"store.address2"}})],1),_c('div',{staticClass:"mt-5 w-full"},[_c('trac-input',{attrs:{"placeholder":"Town"},model:{value:(_vm.store.town),callback:function ($$v) {_vm.$set(_vm.store, "town", $$v)},expression:"store.town"}})],1),_c('div',{staticClass:"mt-5 w-full"},[_c('trac-input',{attrs:{"placeholder":"City"},model:{value:(_vm.store.city),callback:function ($$v) {_vm.$set(_vm.store, "city", $$v)},expression:"store.city"}})],1),_c('div',{staticClass:"mt-5 w-full"},[_c('trac-validation-provider',{attrs:{"name":"state","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('trac-input',{attrs:{"placeholder":"State"},model:{value:(_vm.store.state),callback:function ($$v) {_vm.$set(_vm.store, "state", $$v)},expression:"store.state"}}),(errors[0])?_c('trac-input-error',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }